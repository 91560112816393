import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Contact extends Component {
    render() {
        return (
            <div>
            <section class="progress-section-space bg-common progress-bg-color">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-12">
                            <div class="progress-layout1">
                                <div class="media">
                                    <div class="item-icon">
                                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                                    </div>
                                    <div class="media-body">
                                        <h3>Strategos Company</h3>
                                        <p>12, Akerele street, Gbagada, Lagos. Nigeria</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-12">
                            <div class="progress-layout1">
                                <div class="media">
                                    <div class="item-icon">
                                        <i class="fa fa-users" aria-hidden="true"></i>
                                    </div>
                                    <div class="media-body">
                                        <h3>Email</h3>
                                        <p>info@strategoscompany.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-12">
                            <div class="progress-layout1">
                                <div class="media">
                                    <div class="item-icon">
                                        <i class="fa fa-clone" aria-hidden="true"></i>
                                    </div>
                                    <div class="media-body">
                                        <h3>Phone</h3>
                                        <p>+234 805 567 9890</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        )
    }
}