import React from 'react';
import { BrowserRouter, Switch, Route, Redirect, Link } from 'react-router-dom';
//import logo from './logo.svg';
import './App.css';
//Pages Components
import Header from './components/header.component';
import Footer from './components/footer.component';
import Home from './components/home.component';
import About from './components/about.component';
import Services from './components/services.component';
import Projects from './components/projects.component';
import Contact from './components/contact.component';
import Blog from './components/blog.component';
import BlogPost from './components/blogpost.component';

function App() {
  return (
      <BrowserRouter>
        <Header />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/index" component={Home} exact />
          <Route path="/about" component={About} />
          <Route path="/services" component={Services} />
          <Route path="/projects" component={Projects} />
          <Route path="/contact" component={Contact} />
          <Route path="/blog" component={Blog} />
          <Route path="/blogpost" component={BlogPost} />
          <Redirect to="/index" />
        </Switch>
        <Footer />
      </BrowserRouter>
  );
}

export default App;
