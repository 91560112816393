import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Projects extends Component {
    render() {
        return (
            <div>
            <section id="schedule" class="section-space-default bg-light">
                <div class="container">
                    <div class="section-heading title-black color-dark text-center">
                        <h2>Software Features</h2>
                        <p>Featured benefits and features of the App</p>
                        <img src="img/about/about2.jpg" class="img-fluid" alt="banner" /><br /><br />
                    </div>
                      {/**<div class="schedule-layout2">
                        <ul class="schedule-nav nav nav-tabs">
                            <li class="nav-item">
                                <a href="#one" data-toggle="tab" aria-expanded="false" class="active">
                                    <div class="day-number">Day - 01</div>
                                    <div class="schedule-date">17th Oct, 2018</div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#two" data-toggle="tab" aria-expanded="false">
                                    <div class="day-number">Day - 02</div>
                                    <div class="schedule-date">18th Oct, 2018</div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#three" data-toggle="tab" aria-expanded="false">
                                    <div class="day-number">Day - 03</div>
                                    <div class="schedule-date">20th Oct, 2018</div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#four" data-toggle="tab" aria-expanded="true">
                                    <div class="day-number">Day - 04</div>
                                    <div class="schedule-date">25th Oct, 2018</div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#five" data-toggle="tab" aria-expanded="true">
                                    <div class="day-number">Day - 05</div>
                                    <div class="schedule-date">27th Oct, 2018</div>
                                </a>
                            </li>
                        </ul>
                        <div class="schedule-content">
                            <div class="tab-content">
                                <div role="tabpanel" class="tab-pane fade active show" id="one">
                                    <table class="table table-striped table-responsive">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Topic</th>
                                                <th>Speaker</th>
                                                <th>Time</th>
                                                <th>Hall</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Daizy Chirs</td>
                                                <td>09.00 am - 10.00 am</td>
                                                <td>B.City Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Ayrin Dina</td>
                                                <td>10.30 am - 11.00 am</td>
                                                <td>Times Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Steve John</td>
                                                <td>11.30 am - 12.00 pm</td>
                                                <td>Newyork Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">4</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Mark Willy</td>
                                                <td>12.30 pm - 01.30 pm </td>
                                                <td>B.City Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">5</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Daizy Chirs</td>
                                                <td>02.00 am - 02.30 pm</td>
                                                <td>City Hutt Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">6</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Christian Zozo</td>
                                                <td>03.00 pm - 04.00 pm</td>
                                                <td>B.City Complex</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div role="tabpanel" class="tab-pane fade" id="two">
                                    <table class="table table-striped table-responsive">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Topic</th>
                                                <th>Speaker</th>
                                                <th>Time</th>
                                                <th>Hall</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Daizy Chirs</td>
                                                <td>09.00 am - 10.00 am</td>
                                                <td>B.City Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Ayrin Dina</td>
                                                <td>10.30 am - 11.00 am</td>
                                                <td>Times Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Steve John</td>
                                                <td>11.30 am - 12.00 pm</td>
                                                <td>Newyork Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">4</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Mark Willy</td>
                                                <td>12.30 pm - 01.30 pm </td>
                                                <td>B.City Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">5</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Christian Zozo</td>
                                                <td>03.00 pm - 04.00 pm</td>
                                                <td>B.City Complex</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div role="tabpanel" class="tab-pane fade" id="three">
                                    <table class="table table-striped table-responsive">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Topic</th>
                                                <th>Speaker</th>
                                                <th>Time</th>
                                                <th>Hall</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Daizy Chirs</td>
                                                <td>09.00 am - 10.00 am</td>
                                                <td>B.City Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Daizy Chirs</td>
                                                <td>02.00 am - 02.30 pm</td>
                                                <td>City Hutt Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Christian Zozo</td>
                                                <td>03.00 pm - 04.00 pm</td>
                                                <td>B.City Complex</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div role="tabpanel" class="tab-pane fade" id="four">
                                    <table class="table table-striped table-responsive">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Topic</th>
                                                <th>Speaker</th>
                                                <th>Time</th>
                                                <th>Hall</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Daizy Chirs</td>
                                                <td>09.00 am - 10.00 am</td>
                                                <td>B.City Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Mark Willy</td>
                                                <td>12.30 pm - 01.30 pm </td>
                                                <td>B.City Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Daizy Chirs</td>
                                                <td>02.00 am - 02.30 pm</td>
                                                <td>City Hutt Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">4</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Christian Zozo</td>
                                                <td>03.00 pm - 04.00 pm</td>
                                                <td>B.City Complex</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div role="tabpanel" class="tab-pane fade" id="five">
                                    <table class="table table-striped table-responsive">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Topic</th>
                                                <th>Speaker</th>
                                                <th>Time</th>
                                                <th>Hall</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Daizy Chirs</td>
                                                <td>09.00 am - 10.00 am</td>
                                                <td>B.City Complex</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>
                                                    <a href="single-event.html">Introduction Business</a>
                                                </td>
                                                <td>Ayrin Dina</td>
                                                <td>10.30 am - 11.00 am</td>
                                                <td>Times Complex</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                      </div>*/}
                    <div class="row">
                        <div class="col-12 text-center">
                            <a href="https://app.greymatteragency.com" title="Register Now" class="btn-fill size-md border-radius-5 color-yellow">Register Now</a>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        )
    }
}