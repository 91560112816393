import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Home extends Component {
    render() {
        return (
            <div>
<section class="row the-product" id="product">
<div class="container">
<div class="row section-header wow fadeInUp">
<h2>BizSoft</h2>
<p>BizSoft is a cloud-based enterprise resource planning (ERP) app that assists small to large businesses with the management of customer relationships, sales, communication, accounting, project management and collaboration, recruitment, inventory tracking, attendance, assets maintenance etc.</p>
</div>
<div class="row apple-watch-note-feature text-center">
<img src="images/apple-watch-2.png" alt="" />

{/* <div class="feature-note right top wow fadeInRight">
<div class="indicator">
<div class="plus-icon">
<span class="plus">+</span>
</div>
</div>
<div class="feature-name">
metalic strap
</div>
</div> */}

{/* <div class="feature-note left top wow fadeInLeft">
<div class="indicator">
<div class="plus-icon">
<span class="plus">+</span>
</div>
</div>
<div class="feature-name">
Gps tracker
</div>
</div> */}

{/* <div class="feature-note right bottom wow fadeInUp">
<div class="indicator">
<div class="plus-icon">
<span class="plus">+</span>
</div>
</div>
<div class="feature-name">
Bluetooth 4.2
    </div>
</div> */}

{/* <div class="feature-note left bottom wow fadeInLeft">
<div class="indicator">
<div class="plus-icon">
<span class="plus">+</span>
</div>
</div>
<div class="feature-name">
water resistant
</div>
</div> */}
</div>
</div>
</section>

<section class="row how-it-works">
<div class="container">
<div class="row section-header v2 wow fadeInUp">
<h2>how it works</h2>
<p>BizSoft enable you control your entire organization business processes, activities and operational visibility to grow your business. The software fit every company size and price budget and our unique modules allow our software to scale with your business.</p>
</div>
<div class="row work-processes">

<div class="col-sm-4 work-process wow fadeIn">
<div class="row m0 process-icon">
<img src="images/desktop.png" alt="" />
</div>
<h3>Register Your Business</h3>
<p>Sign up is very fast and easy, setup up your company info and structures, and enlist your staffs on the platform.</p>
</div>

<div class="col-sm-4 work-process wow fadeIn" data-wow-delay="0.5s">
<div class="row m0 process-icon">
<img src="images/toggles.png" alt="" />
</div>
<h3>Post Your Projects</h3>
<p>Post your projects/tasks and data on the platform while you get to monitor, track and access updates and reports real-time.</p>
</div>

<div class="col-sm-4 work-process wow fadeIn" data-wow-delay="1s">
<div class="row m0 process-icon">
<img src="images/trophy.png" alt="" />
</div>
<h3>Have Real-Time Analytics!</h3>
<p>Automate and track everything you do - centralized, online, and accessible from anywhere with any device.</p>
</div>
</div>
</div>
</section>

<section class="row the-benefits" id="features">
<div class="container">
<div class="row section-header wow fadeInUp">
<h2>The Benefits</h2>
<p>All your data is located in a single database, giving your users simultaneous access to the collective information. This allows your decision makers to be informed and aware of what is happening real-time in the office.</p>
</div>
<div class="row benefit-notes">

<div class="col-sm-6 col-md-4 benefit wow fadeInUp">
<div class="media">
<div class="media-left">
<span><i class="li_user"></i></span>
</div>
<div class="media-body">
<h4>Customer Management</h4>
<p>Manage and monitor your Customers and their transactions with your company. </p>
</div>
</div>
</div>

<div class="col-sm-6 col-md-4 benefit wow fadeInUp" data-wow-delay="0.3s">
<div class="media">
<div class="media-left">
<span><i class="li_truck"></i></span>
</div>
<div class="media-body">
<h4>Vendors Management</h4>
<p>Monitor your Vendors, assets, procurements and third-party purchases in your company. </p>
</div>
</div>
</div>

<div class="col-sm-6 col-md-4 benefit wow fadeInUp" data-wow-delay="0.6s">
<div class="media">
<div class="media-left">
<span><i class="li_bubble"></i></span>
</div>
<div class="media-body">
<h4>Live Communications</h4>
<p>Chat and collaborate with your team real-time with video and audio conference and live chat. </p>
</div>
</div>
</div>

<div class="col-sm-6 col-md-4 benefit wow fadeInUp" data-wow-delay="0.9s">
<div class="media">
<div class="media-left">
<span><i class="li_cup"></i></span>
</div>
<div class="media-body">
<h4>Staff Management</h4>
<p>Monitor staffs and their performances with real-time task management and increase production efficiency. </p>
</div>
</div>
</div>

<div class="col-sm-6 col-md-4 benefit wow fadeInUp" data-wow-delay="1.2s">
<div class="media">
<div class="media-left">
<span><i class="li_banknote"></i></span>
</div>
<div class="media-body">
<h4>Products & Transactions</h4>
<p>Product and inventory management, income and expense tracking, record transactions, generate reports. </p>
</div>
</div>
</div>

<div class="col-sm-6 col-md-4 benefit wow fadeInUp" data-wow-delay="1.5s">
<div class="media">
<div class="media-left">
<span><i class="li_shop"></i></span>
</div>
<div class="media-body">
<h4>Assets Management</h4>
<p>Track and record assets throughout their entire life cycle, from planning and acquisition to disposal and replacement. </p>
</div>
</div>
</div>
</div>
</div>
</section>

<section class="row left-right-contents">
<div class="container">
<div class="row ">
<div class="col-sm-12 col-md-4 col-md-push-4 text-center wow fadeIn">
<img src="images/apple-watch-3.png" alt="" />
</div>
<div class="col-md-4 col-sm-6 col-md-pull-4 left-content">
<div class="media wow fadeInUp">
<div class="media-left">
<span><i class="li_key"></i></span>
</div>
<div class="media-body">
<h4>Fast and secure</h4>
<p>We secure and/or encrypts data, allowing only approved parties access to sensitive information on the platform.  </p>
</div>
</div>
<div class="media wow fadeInUp" data-wow-delay="0.3s">
<div class="media-left">
<span><i class="li_banknote"></i></span>
</div>
<div class="media-body">
<h4>Affordable Pricing</h4>
<p>Our solutions are affordable and easily accessible to businesses irrespective of budget with available plans to fit.  </p>
</div>
</div>
<div class="media wow fadeInUp" data-wow-delay="0.6s">
<div class="media-left">
<span><i class="li_diamond"></i></span>
</div>
<div class="media-body">
<h4>Extensive Modules</h4>
<p>We have various modules to enable you achieve whatever functionalies to help make your business activities easier. </p>
</div>
</div>
</div>
<div class="col-md-4 col-sm-6 right-content">
<div class="media wow fadeInUp">
<div class="media-left">
<span><i class="li_like"></i></span>
</div>
<div class="media-body">
<h4>Stay in touch</h4>
<p>Monitor and access your data and activities on the platform real-time with our mobile app available on all stores. </p>
</div>
</div>
<div class="media wow fadeInUp" data-wow-delay="0.3s">
<div class="media-left">
<span><i class="li_video"></i></span>
</div>
<div class="media-body">
<h4>Watch Your Office 24/7</h4>
<p>View and keep track of all your office activities and happenings 24/7 where ever you are any where in the world.  </p>
</div>
</div>
<div class="media wow fadeInUp" data-wow-delay="0.6s">
<div class="media-left">
<span><i class="li_lab"></i></span>
</div>
<div class="media-body">
<h4>Business Intelligence</h4>
<p>Analyze, transform and report data for business intelligence to grow your business with Artifical Intelligence. </p>
</div>
</div>
</div>
</div>
</div>
</section>

<section class="row our-collection">
<div class="container">
<div class="row section-header wow fadeInUp">
<h2>Our Pricing</h2>
<p>BizSoft software has solutions to fit every company size and price point and our unique modules allow our software to scale with your business.</p>
</div>
<div class="row collections">

<div class="col-sm-6 col-md-3 item wow fadeIn">
<div class="row m0 featured-img">
<img src="images/collection-1.jpg" alt="" />
</div>
<h4 class="title">Bronze Package</h4>
<h5 class="category">5 staffs</h5>
<h5 class="category">10 products</h5>
<h5 class="category">20 customers</h5>
<h5 class="category">Real-time chat</h5>
<h5 class="category"><strike>Business intelligence</strike></h5>
<h4 class="price">N15,000 monthly</h4>
<a href="#product-choose" class="btn">CHOOSE</a>
</div>

<div class="col-sm-6 col-md-3 item wow fadeIn" data-wow-delay="0.5s">
<div class="row m0 featured-img">
<img src="images/collection-2.jpg" alt="" />
</div>
<h4 class="title">Silver Package</h4>
<h5 class="category">15 staffs</h5>
<h5 class="category">25 products</h5>
<h5 class="category">50 customers</h5>
<h5 class="category">Real-time chat</h5>
<h5 class="category"><strike>Business intelligence</strike></h5>
<h4 class="price">N25,000 monthly</h4>
<a href="#product-choose" class="btn">CHOOSE</a>
</div>

<div class="col-sm-6 col-md-3 item wow fadeIn" data-wow-delay="1s">
<div class="row m0 featured-img">
<img src="images/collection-3.jpg" alt="" />
</div>
<h4 class="title">Gold Package</h4>
<h5 class="category">32 staffs</h5>
<h5 class="category">50 products</h5>
<h5 class="category">Unlimited customers</h5>
<h5 class="category">Real-time chat</h5>
<h5 class="category"><strike>Business intelligence</strike></h5>
<h4 class="price">N50,000 monthly</h4>
<a href="#product-choose" class="btn">CHOOSE</a>
</div>

<div class="col-sm-6 col-md-3 item wow fadeIn" data-wow-delay="1.5s">
<div class="row m0 featured-img">
<img src="images/collection-4.jpg" alt="" />
</div>
<h4 class="title">Platium Package</h4>
<h5 class="category">Unlimited staffs</h5>
<h5 class="category">Unlimited products</h5>
<h5 class="category">Unlimited customers</h5>
<h5 class="category">Real-time chat</h5>
<h5 class="category">Business intelligence</h5>
<h4 class="price">N100,000 monthly</h4>
<a href="#product-choose" class="btn">CHOOSE</a>
</div>
</div>
</div>
</section>

<section class="row the-watch">
<div class="container">
<div class="row">
<div class="col-md-5 col-md-offset-1 text-center the-watch-img wow zoomIn">
<img src="images/the-watch.png" alt="" class="img-responsive" />
</div>
<div class="col-md-6 the-watch-features">
<div class="row section-header v3 wow fadeIn">
<h2>Connect On The Go</h2>
<p>Stay updated and access your office on any device and any time irrespective of device.</p>
</div>
<ul class="nav">
<li class="wow fadeIn" data-wow-delay="0.2s">Available online on the Web</li>
<li class="wow fadeIn" data-wow-delay="0.4s">Android and iOS Support</li>
<li class="wow fadeIn" data-wow-delay="0.6s">Desktop software support</li>
<li class="wow fadeIn" data-wow-delay="0.8s">IOT coming soon</li>
</ul>
</div>
</div>
</div>
</section>

{/* <section class="row split-columns">
<div class="row m0 split-column wow fadeIn">
<div class="col-sm-6 image text-right">
<img src="images/split-1.jpg" alt="" />
</div>
<div class="col-sm-6 texts">
<div class="texts-inner row m0">
<h2>Every Apps you wanted</h2>
<p>You won’t just send and receive messages, calls, and mail more easily and efficiently. You’ll express yourself in unique, fun, and more personal ways. Communicating with Watch is discreet, sophisticated, and nuanced. a whole new way to connect with others.</p>
</div>
</div>
</div>
<div class="row m0 split-column wow fadeIn">
<div class="col-sm-6 col-sm-push-6 image">
<img src="images/split-2.jpg" alt="" />
</div>
<div class="col-sm-6 col-sm-pull-6 texts">
<div class="texts-inner row m0">
<h2>Health and Fitness</h2>
<p>Fitness isn’t just about running, biking, or hitting the gym. It’s also about being active throughout the day. Watch measures all the ways you move, such as walking the dog, taking the stairs, or playing with your kids. It even keeps track of when you stand up.</p>
</div>
</div>
</div>
</section> */}

<section class="row reviews" id="reviews">
<div class="container">
<div class="row section-header wow fadeInUp">
<h2>Reviews</h2>
<p>Don’t take our word,. See what our customers says about our app. We have got several positive reviews.</p>
</div>
<div class="row">

<div class="review col-sm-4 wow fadeIn">
<img src="images/quote.png" alt="" class="review-sign" />
<p>Great for automating your business and monitor activities.</p>
<img src="images/reviewer1.png" alt="" class="reviewer" />
</div>

<div class="review col-sm-4 wow fadeIn" data-wow-delay="0.3s">
<img src="images/quote.png" alt="" class="review-sign" />
<p>BizSoft enables us access and control our business data and records real-time.</p>
<img src="images/reviewer2.png" alt="" class="reviewer" />
</div>

<div class="review col-sm-4 wow fadeIn" data-wow-delay="0.6s">
<img src="images/quote.png" alt="" class="review-sign" />
<p>BizSoft is the only software that has truly combined all technology necessities of business into 1 platform. That includes ERP, CRM, automation etc for our ease.</p>
<img src="images/reviewer3.png" alt="" class="reviewer" />
</div>
</div>
</div>
</section>

<section class="row  featured-on">
<div class="container">
<div class="row">
<h3>As featured on</h3>
<ul class="nav nav-justified">
<li class="wow fadeInUp" data-wow-delay="0.2s"><img src="images/featured1.png" alt="" /></li>
<li class="wow fadeInUp" data-wow-delay="0.4s"><img src="images/featured2.png" alt="" /></li>
<li class="wow fadeInUp" data-wow-delay="0.6s"><img src="images/featured3.png" alt="" /></li>
<li class="wow fadeInUp" data-wow-delay="0.8s"><img src="images/featured4.png" alt="" /></li>
<li class="wow fadeInUp" data-wow-delay="1s"><img src="images/featured5.png" alt="" /></li>
<li class="wow fadeInUp" data-wow-delay="1.2s"><img src="images/featured6.png" alt="" /></li>
</ul>
</div>
</div>
</section>

<section class="row faqs">
<div class="container">
<div class="row section-header wow fadeInUp">
<h2>faq</h2>
<p>Got questions? We’ve got answers. If you have some other questions, feel free to send us an email to <a href="#">info@bizsoft.com.ng</a></p>
<p><br /><br />An ERP system provides the solid operational backbone manufacturers and distributors need to improve the volume of production and fulfillment of orders while reducing costs. By optimizing your manufacturing and distribution operations with ERP, you'll also be able to focus on new business opportunities.</p>
</div>
<div class="row">

<div class="col-sm-6 faq wow fadeInUp">
<h4>What is ERP?</h4>
<p>ERP stands for "Enterprise Resource Planning". The definition of enterprise resource planning is an integrated software solution used to manage a company's resources. ERP systems integrate all business management functions, including planning, inventory/materials management, engineering, order processing, manufacturing, purchasing, accounting and finance, human resources, and more.</p>
</div>

<div class="col-sm-6 faq wow fadeInUp">
<h4>What are the benefits of ERP?</h4>
<p>The benefits derived from ERP can far outweigh the costs of the system, providing that the system is selected carefully and is appropriate for your company from a feature, cost, and technology standpoint. Some of the benefits realized are:
<br />
<ul>
<li>A single integrated system</li>
<li>Streamlining processes and workflows</li>
<li>Reduce redundant data entry and processes</li>
<li>Information sharing across departments</li>
<li>Improved workflow and efficiency</li>
<li>Improved customer satisfaction based on improved on-time delivery, increased quality, shortened delivery times</li>
<li>Reduced inventory costs resulting from better planning, tracking and forecasting of requirements</li>
<li>Turn collections faster based on better visibility into accounts and fewer billing and/or delivery errors</li>
<li>Decrease in vendor pricing by taking better advantage of quotation comparison and tracking vendor performance</li>
</ul></p>
</div>

<div class="col-sm-6 faq wow fadeInUp" data-wow-delay="0.3s">
<h4>How long does it take to setup?</h4>
<p>We are proud to say that our set-up process is fast and instant. You just have to register online and choose your preferred pricing package and you are good to go.</p>
</div>

<div class="col-sm-6 faq wow fadeInUp" data-wow-delay="0.3s">
<h4>What technology do you use?</h4>
<p>BizSoft is developed mainly in Angular and PHP. The application runs faster and is highly secured and cost effective. It also leverages the Server/Client, allowing you to work on any technology, be it LAN, WAN, Internet or Intranet.</p>
</div>
</div>
</div>
</section>

{/* <section class="row tech-specs">
<div class="container">
<div class="row section-header wow fadeInUp">
<h2>tech specs</h2>
<p>Need more info? Please have a look at the tech specs of the watch. We’ve used Latest technology &amp; quality materials</p>
</div>

<div class="row tech-specs-row">
<div class="col-sm-6 col-md-4 tech-spec wow fadeIn" data-wow-delay="0s">
<h4>Compatibility</h4>
 <ul class="nav">
<li>iPhone iOS 7 &amp; up</li>
<li>Android 4.3 &amp; uptexts</li>
<li>Bluetooth 4.0</li>
<li>Notification for calls / texts</li>
</ul>
</div>
<div class="col-sm-6 col-md-4 tech-spec wow fadeIn" data-wow-delay="0.3s">
<h4>App</h4>
<ul class="nav">
<li>Set daily goals</li>
<li>Analyze history</li>
<li>Sync to phone &amp; cloud</li>
<li>Connect to Apple health kit</li>
<li>Connect to Google fit</li>
<li>Watch firmware upgrade</li>
</ul>
</div>
<div class="col-sm-6 col-md-4 tech-spec wow fadeIn" data-wow-delay="0.6s">
<h4>Vibration silent alarms</h4>
<ul class="nav">
<li>Phone notifications</li>
<li>Daily goal reached</li>
<li>Daily wake-up alarm</li>
<li>Location assistance</li>
<li>Accessiblity features</li>
</ul>
</div>
<div class="col-sm-6 col-md-4 tech-spec wow fadeIn" data-wow-delay="0.9s">
<h4>Watch</h4>
<ul class="nav">
<li>12.6mm thickness</li>
<li>3.16L stainless steel case</li>
<li>40mm diameter</li>
<li>5 ATM water resistant</li>
<li>Sapphire glass crystal</li>
</ul>
</div>
<div class="col-sm-6 col-md-4 tech-spec wow fadeIn" data-wow-delay="1.2s">
<h4>Straps</h4>
<ul class="nav">
<li>Italian leather with natural lining</li>
<li>Comfort sport fabric</li>
<li>Quick-release pins</li>
</ul>
</div>
<div class="col-sm-6 col-md-4 tech-spec wow fadeIn" data-wow-delay="1.5s">
<h4>Activity tracking</h4>
<ul class="nav">
<li>Step counting</li>
<li>Distance</li>
<li>Calories burned</li>
<li>Swimming (strokes)</li>
</ul>
</div>
<div class="col-sm-6 col-md-4 tech-spec wow fadeIn" data-wow-delay="1.8s">
<h4>Timekeeping</h4>
<ul class="nav">
<li>Swiss ETA movement</li>
<li>Accurate, reliable</li>
</ul>
</div>
<div class="col-sm-6 col-md-4 tech-spec wow fadeIn" data-wow-delay="2.1s">
<h4>Battery power</h4>
<ul class="nav">
<li>Standard CR2025 6+ months</li>
<li>Standard 364 for timekeeping 5+ years</li>
<li>Solar Power available as aupgrade</li>
</ul>
</div>
<div class="col-sm-6 col-md-4 tech-spec wow fadeIn" data-wow-delay="2.4s">
<h4>Sensors</h4>
<ul class="nav">
<li>MEMS 3-axis accelerometer</li>
<li>Highly accurate</li>
<li>Low power consumption</li>
</ul>
</div>
</div>

<div class="row tech-specs-row">
</div>

<div class="row tech-specs-row">
</div>
</div>
</section> */}

{/* <section class="row team">
<div class="container">
<div class="row section-header wow fadeInUp">
<h2>meet the team</h2>
<p>We are a small group of inverntors, hackers and designers from the differrent parts of the world on a mission.</p>
</div>
<div class="row team_members">

<div class="col-md-3 col-sm-6 member wow fadeInUp">
<div class="row m0 inner">
<div class="row m0 image">
<img src="images/team-1.jpg" alt="" />
</div>
<div class="texts row m0">
<h4>Gary Elliott</h4>
<h5>Co-Founder and CEO</h5>
</div>
</div>
</div>

<div class="col-md-3 col-sm-6 member wow fadeInUp" data-wow-delay="0.3s">
<div class="row m0 inner">
<div class="row m0 image">
<img src="images/team-2.jpg" alt="" />
</div>
<div class="texts row m0">
<h4>Jeffrey Allen</h4>
<h5>Co-Founder and CTO</h5>
</div>
</div>
</div>

<div class="col-md-3 col-sm-6 member wow fadeInUp" data-wow-delay="0.6s">
<div class="row m0 inner">
<div class="row m0 image">
<img src="images/team-3.jpg" alt="" />
</div>
<div class="texts row m0">
<h4>Sara Mendez</h4>
<h5>Lead Developer, Hacker</h5>
</div>
</div>
</div>

<div class="col-md-3 col-sm-6 member wow fadeInUp" data-wow-delay="0.9s">
<div class="row m0 inner">
<div class="row m0 image">
<img src="images/team-4.jpg" alt="" />
</div>
<div class="texts row m0">
<h4>Albert Castro</h4>
<h5>3D Designer &amp; Prototyper</h5>
</div>
</div>
</div>
</div>
</div>
</section> */}

{/* <section class="row timeline">
<div class="container">
<div class="row section-header wow fadeInUp">
<h2>timeline</h2>
<p>Here’s a roadmap of our product to highlight the milestones from the initial phase to delivery and future updates.</p>
</div>
<div class="row timeline-row">
<div class="row m0 timeline-post">
<div class="bar-content">
<div class="inner">now</div>
</div>
</div>
<div class="row m0 timeline-post has-content wow fadeInUp">
<div class="col-sm-6 half-side date">
23 jan 2014
</div>
<div class="col-sm-6 half-side content">
<div class="inner">
<h4 class="title">Mass Production</h4>
</div>
</div>
</div>
<div class="row m0 timeline-post has-content odd wow fadeInUp">
<div class="col-sm-6 half-side date">
23 jan 2014
</div>
<div class="col-sm-6 half-side content">
<div class="inner">
<h4 class="title">Testing Pre-Production Samples</h4>
</div>
</div>
</div>
<div class="row m0 timeline-post has-content wow fadeInUp">
<div class="col-sm-6 half-side date">
23 jan 2014
</div>
<div class="col-sm-6 half-side content">
<div class="inner">
<h4 class="title">Croudfunding Campaign</h4>
<p>And an optional description. You may add little description of your milestone if you want.</p>
</div>
</div>
</div>
<div class="row m0 timeline-post has-content odd wow fadeInUp">
<div class="col-sm-6 half-side date">
23 jan 2014
</div>
<div class="col-sm-6 half-side content">
<div class="inner">
<h4 class="title">Preview at CES</h4>
</div>
</div>
</div>
<div class="row m0 timeline-post wow fadeInUp">
<div class="bar-content">
<div class="inner">2015</div>
</div>
</div>
<div class="row m0 timeline-post has-content wow fadeInUp">
<div class="col-sm-6 half-side date">
23 jan 2014
</div>
<div class="col-sm-6 half-side content">
<div class="inner">
<h4 class="title">3D Prototype &amp; Testing</h4>
</div>
</div>
</div>
<div class="row m0 timeline-post has-content odd wow fadeInUp">
<div class="col-sm-6 half-side date">
23 jan 2014
</div>
<div class="col-sm-6 half-side content">
<div class="inner">
<h4 class="title">Research and Development</h4>
</div>
</div>
</div>
<div class="row m0 timeline-post">
<div class="bar-content">
<div class="inner">idea</div>
</div>
</div>
</div>
</div>
</section> */}

<section class="row mobile-app">
<div class="container">
<div class="row">
<div class="col-sm-6 col-sm-push-6 wow fadeIn">
<h2>Mobile App Available</h2>
<p>Stay updated and access your business info on any device and any time.</p>
<div class="row m0 downloads-btns">
<a href="#" class="dload-link"><img src="images/app-store.png" alt="" /></a>
<a href="#" class="dload-link"><img src="images/google-play.png" alt="" /></a>
</div>
</div>
<div class="col-sm-6 col-sm-pull-6 wow fadeInUp">
<img src="images/app-screen.png" alt="" class="mobile-img" />
</div>
</div>
</div>
</section>

<section class="row newsletter">
<div class="container">
<div class="row section-header wow fadeInUp">
<h2>newsletter</h2>
<p>Subscribe to our product newsletter to get notified when we launch the product or when we completed a milestone</p>
</div>
<form id="subscribeform" class="row newsletter-form" method="post">
<div class="input-group">
<input type="email" class="form-control" name="email" placeholder="Enter Your Email Address" />
<span class="input-group-addon">
<button type="submit" id="js-subscribe-btn"><img src="images/right-angle-white.png" alt="" /></button>
</span>
</div>
<div id="js-subscribe-result" class="text-center" data-success-msg="Almost finished. Please check your email and verify." data-error-msg="Oops. Something went wrong.">
<p>
<img src="images/protect.png" alt="" />No Spam. We Promise. Unsubscribe anytime.
</p>
</div>
</form>
</div>
</section>

<section class="row contact" id="contact">


<div id="mapBox" class="row m0" data-lat="6.6470" data-lon="3.3742" data-zoom="15"></div>

<div class="flip-box-container row m0">
<div class="flip-box row m0 wow fadeIn">

<div class="row contact-box flip-box-part">
<h2>get in touch</h2>
<ul class="nav">
<li><i class="fa fa-map-marker"></i>13, Olu-Akerele Street, Ikeja,
<br />Lagos, Nigeria.</li>
<li><i class="fa fa-phone"></i>+(234) 809 701 1119</li>
<li><i class="fa fa-envelope"></i><a href="">info@bizsoft.com.ng</a></li>
</ul>
<button class="flip-contact-box btn btn-block">contact us</button>
</div>

<div class="row contact-form flip-box-part">
<a href="javascript:void(0);" class="js-close-flip">&times;</a>

<form id="phpcontactform" method="POST">
<div class="row">
<div class="form-group col-sm-6">
<input type="text" name="name" class="form-control" placeholder="Name" required />
</div>
<div class="form-group col-sm-6">
<input type="email" name="email" class="form-control" placeholder="Email Address" required />
</div>
</div>
<div class="form-group">
<input type="text" name="subject" class="form-control" placeholder="Subject" required />
</div>
<div class="form-group">
<textarea class="form-control" name="message" placeholder="Message" required></textarea>
</div>
<button type="submit" id="js-contact-btn" class="btn btn-block">Send Message</button>
<div id="js-contact-result" data-success-msg="Form submitted successfully." data-error-msg="Oops. Something went wrong."></div>
</form>

</div>

</div>
</div>

</section>
        </div>
        )
    }
}