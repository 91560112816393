import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Services extends Component {
    render() {
        return (
            <div>
            <section id="speakers" class="section-space-top-default bg-light">
                <div class="container-fluid">
                    <div class="section-heading title-black color-dark text-center">
                        <h2>Why Metrics Manager?</h2>
                        <p>What does our App do for you and our unique selling point</p>
                    </div>
                    <div class="media-body media-body-box">
                        <img src="img/figure/figure1.jpg" class="img-fluid" alt="banner" /><br /><br />
                        <p>The best way to have a great team of employees is to have an effective HR department with the right people and software products to collect and manage information about the organization’s human resources. It’s every human. <br /></p>
                        <p>Companies can effectively manage their human resources if they use the appropriate core HR software products. With the right core HR software or information system, companies can easily perform the key functions of the HR department, capture basic data about employees, manage employee data, and generate valuable reports. <br /></p>
                        <p>Human Resources Management software are tools used to automate the Human Resources process of a company. These software applications computerize and integrate several human resource processes like recruitment, training, payroll, administration of benefits, performance appraisal and analysis, etc., into one robust package. Many organizations are rightly beginning to understand that their most important and valuable resource is their human capital and this is where Metrics Manager App is needed to achieve these for you.</p>
                    </div>
                </div>
            </section>
            </div>
        )
    }
}