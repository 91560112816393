import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Header extends Component {
    render() {
        return (
<header class="row" id="header">
<nav class="navbar navbar-default navbar-fixed-top">
<div class="container">

<div class="navbar-header">
<a class="navbar-brand" href="#header"><img src="images/logo.png" alt="" /></a>
<button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#main-navbar" aria-expanded="false">
<span class="sr-only">Toggle navigation</span>
<span class="icon-bar"></span>
<span class="icon-bar"></span>
<span class="icon-bar"></span>
</button>
</div>

<div class="collapse navbar-collapse" id="main-navbar">
<a href="https://app.bizsoft.com.ng/" class=" btn btn-warning pull-right hidden-sm hidden-xs">Access Account</a>
<ul class="nav navbar-nav navbar-right">
<li><a href="#product">About</a></li>
<li><a href="#features">Features</a></li>
<li><a href="#reviews">Reviews</a></li>
<li><a href="#contact">Contact</a></li>
</ul>
</div>

</div>

</nav>
<div class="top-banner row m0 text-center fadeInOnLoad">
<div class="container">
<h2>All-In-One ERP App</h2>
<p>Cloud-based enterprise resource planning (ERP) app that assists small to large businesses. </p>
<a href="https://app.bizsoft.com.ng/" class="btn btn-primary btn-lg video">Sign Up NOW</a>
<br />
<a href="https://app.bizsoft.com.ng/" class="btn btn-warning btn-lg visible-sm-inline-block visible-xs-inline-block">Access Your Account</a>
<div class="row apple-watch">
<img src="images/apple-watch.png" alt="" class="watch_img" />
</div>
</div>
</div>
</header>
        )
    }
}