import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Blog extends Component {
    render() {
        return (
            <div>
            <section id="blog" class="section-space-default-less30 bg-accent">
                <div class="container">
                    <div class="section-heading title-black color-dark text-center">
                        <h2>Our Latest Blogs</h2>
                        <p>Learn from our articles on HR best practices the world over.</p>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="blog-layout2">
                                <div class="media align-items-center media-none--sm">
                                    <div class="item-img">
                                        <img src="img/blog/blog4.jpg" alt="blog" class="img-fluid" />
                                        <div class="item-date">26
                                            <span> Oct</span>
                                        </div>
                                    </div>
                                    <div class="media-body media-body-box">
                                        <div class="item-title">
                                            <h3 class="title title-medium color-dark hover-primary">
                                                <a href="single-blog.html">Event Management Notice For All</a>
                                            </h3>
                                        </div>
                                        <div class="item-deccription">
                                            <p>Article content goes here with brief details captivating enough to draw the audience to click to read more. This is an intro to the article and usually the first paragraph of the content.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="blog-layout2">
                                <div class="media align-items-center media-none--sm">
                                    <div class="item-img">
                                        <img src="img/blog/blog5.jpg" alt="blog" class="img-fluid" />
                                        <div class="item-date">24
                                            <span> Oct</span>
                                        </div>
                                    </div>
                                    <div class="media-body media-body-box">
                                        <div class="item-title">
                                            <h3 class="title title-medium color-dark hover-primary">
                                                <a href="single-blog.html">Event Management Notice For All</a>
                                            </h3>
                                        </div>
                                        <div class="item-deccription">
                                        <p>Article content goes here with brief details captivating enough to draw the audience to click to read more. This is an intro to the article and usually the first paragraph of the content.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="blog-layout2">
                                <div class="media align-items-center media-none--sm">
                                    <div class="item-img">
                                        <img src="img/blog/blog6.jpg" alt="blog" class="img-fluid" />
                                        <div class="item-date">20
                                            <span> Oct</span>
                                        </div>
                                    </div>
                                    <div class="media-body media-body-box">
                                        <div class="item-title">
                                            <h3 class="title title-medium color-dark hover-primary">
                                                <a href="single-blog.html">Event Management Notice For All</a>
                                            </h3>
                                        </div>
                                        <div class="item-deccription">
                                        <p>Article content goes here with brief details captivating enough to draw the audience to click to read more. This is an intro to the article and usually the first paragraph of the content.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        )
    }
}