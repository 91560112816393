import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class About extends Component {
    render() {
        return (
            <div>
            <section id="about" class="section-space-default bg-common bg-accent">
                <div class="container">
                    <div class="section-heading title-black color-dark text-center">
                        <h2>About Metrics Manager</h2>
                    </div>
                    <div class="about-layout2">
                        <div class="media align-items-center media-none--md">
                            <div class="video-area">
                                <img src="img/about/about1.jpg" alt="about" class="img-fluid width-100" />
                                <a class="play-btn popup-youtube" href="#" target="_blank">
                                    <img src="img/figure/play.png" alt="play" class="img-fluid" />
                                </a>
                            </div>
                            <div class="media-body media-body-box">
                                <h3 class="title-bold color-primary size-lg margin-b-10">HR Operations Made Easy</h3>
                                <h3 class="title-medium color-dark">Discover How Our App Can Transform Your Organization</h3>
                                <p>Our HR software help organizations to manage its human resources while the Employer can monitor the use of leave, record work time, create timetables for the entire company and manage employees' personal files. The database is fully compliant with worlds top data security standards (including GDPR) and can be accessed 24/7 from any location or device.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        )
    }
}