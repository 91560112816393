import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends Component {
    render() {
        return (
<footer class="row">
<div class="container">
<div class="row m0 social-links">
<ul class="nav">
<li class="wow fadeInUp"><a href="#"><i class="fa fa-facebook"></i></a></li>
<li class="wow fadeInUp" data-wow-delay="0.1s"><a href="#"><i class="fa fa-twitter"></i></a></li>
<li class="wow fadeInUp" data-wow-delay="0.2s"><a href="#"><i class="fa fa-linkedin"></i></a></li>
<li class="wow fadeInUp" data-wow-delay="0.3s"><a href="#"><i class="fa fa-youtube"></i></a></li>
<li class="wow fadeInUp" data-wow-delay="0.4s"><a href="#"><i class="fa fa-google-plus"></i></a></li>
<li class="wow fadeInUp" data-wow-delay="0.5s"><a href="#"><i class="fa fa-pinterest"></i></a></li>
</ul>
</div>
<div class="row m0 menu-rights">
<ul class="nav footer-menu">
<li><a href="about">About</a></li>
<li><a href="terms">Terms of Use</a></li>
<li><a href="policy">Privacy Policy</a></li>
<li><a href="#mobileapp">Mobile App</a></li>
<li><a href="presskit">PressKit</a></li>
</ul>
<p>Copyright © 2023 PaperWorks First Real Nigeria Ltd.
<br class="small-divide" /> All rights reserved</p>
</div>
</div>
</footer>
        )
    }
}